<template>

	<div class="content-tab info-content overviewStudy_tab w-17-percent-cl-2 w-15-percent-cl-3">
        <b-table responsive :fields="fields" :items="items" tbody-tr-class="size-14 table-row cursor-pointer" id="overviewTab" @head-clicked="headClicked" @row-clicked="single_detail" show-empty style="white-space: nowrap" class="mb-3">
	    	<template #head(id)="data">
	    		<div class="d-block text-left">
	    			<span class="pl-1">{{ data.label }} </span>
			        <span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 40px;">
			        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
			        </span>				    
	    		</div>						        
		    </template>
		    <template #head()="data">
	    		<span class="d-block text-center">{{ data.label }}</span>
		    </template>
		    <template #head(no)="data">
	    		<span>{{ data.label }}</span>
		    </template>
		    <template #cell(no)="data">
	    		<span>SV</span>
					<span v-if="data.item.id <= 9">000</span><span v-else-if="data.item.id > 9 && data.item.id <= 99 ">00</span><span v-else-if="data.item.id > 99 && data.item.id <= 999 ">0</span>{{ data.item.id }}
				</span>
		    </template>
		    <template #head(client)="data">
	    		<span>{{ data.label }}</span>
		    </template>
		    <template #cell(client)="data">
				<span class="maxTwoRow">{{ data.value.name }}</span>
			</template>
			<template #cell(id)="data">
				<span class="d-block text-center">{{ data.item.country_name }}</span>
			</template>
			<template #cell(type_engagement)="data">
				<span class="d-block text-center">
					<span v-if="data.item.type_engagement == 0">Paid</span>
					<span v-if="data.item.type_engagement == 1">Complimentary</span>
				</span>
			</template>	
			<template #cell(owner)="data">
				<span class="d-block text-center">{{ data.item.owner.full_name }}</span>
			</template>
			<template #cell(college_visited)="data">
				<span class="d-block text-center">
					{{ data.item.college_visited_name.replace(/,/g, ', ')}}
				</span>
			</template>
			<template #cell()="data">
				<span class="d-block text-center">{{ data.value }}</span>
			</template>
			<template #empty="scope">
				<template v-if="search">
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
				</template>
				<template v-else>
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
				</template>
		    </template>
			<template #cell(action)="data">
				<div class="d-block text-center">
					<template v-if="userData && (permission ? permission.edit : false)">
						<span class="px-05 cursor-pointer" @click.stop="showDeleteAction(data.item.id)">
							<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
									fill="#DD3E47" />
							</svg>
						</span>
					</template>
					<template v-else>
						<span class="px-05">
							<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
									fill="#B1B1B1" />
							</svg>
						</span>
					</template>

				</div>
			</template>
		</b-table>
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="overviewTab"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		</div>
    </div>
</template>

<script>
	import { codeResponsive } from '@/views/table/bs-table/code.js'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	export default {
	  	props: {
            search: {
                type: String,
                default: null
            },
			permission: {
				type: [Array, String, Number, Object],
			}
        },
	  	data() {
		    return {
				userData: JSON.parse(localStorage.getItem('userData')),
		    	fields: [
                	{ key: 'no', label: 'Visit ID', isSort: false },
			        { key: 'client', label: 'Client (Pay Master)', isSort: false },
			        { key: 'id', label: 'Country', isSort: true },
			        { key: 'type_engagement', label: 'Engagement Type', isSort: false },
			        { key: 'start_date', label: 'Start Date', isSort: false },
			        { key: 'end_date', label: 'End Date', isSort: false },
			        { key: 'owner', label: 'Study Visit Owner', isSort: false },
			        { key: 'project_value', label: 'Study Visit Value', isSort: false },
			        { key: 'college_visited', label: 'Colleges Visted', isSort: false },
					{ key: 'action', label: 'Actions', isSort: false },
			    ],
		    	items: [],	  
			    sort: {
			    	field: 'id',
			    	type: 'asc'
			    },
			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		codeResponsive,
        		nameCountry: null,
            }
		},
		watch: {
			// "$route": function(val) {
			// 	if(val){	
			// 		this.getNamebyCountry()
			// 	}
			// },
			currentPage(val) {
				this.fetchItemByCountry(this.search)
			}
		},
		created(){
			this.fetchItemByCountry(this.search)
			// this.getNamebyCountry()
		},
		methods: {
			fetchItemByCountry(search){
				this.$store
				.dispatch('project/itemStudyVisit', {
					limit: this.perPage,
					page: this.currentPage,
					filter: { "list_search": [ 
						{ "field": "search","keyword": search },
						{ "field": "status","keyword": "0"},
						{ "field": "country_id","keyword": this.$route.params.id }
					]},
					sort: this.sort
				})
				.then(response => {
					this.items = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			// getNamebyCountry(){
			// 	this.$store
			// 	.dispatch('country/fetchCountryID', this.$route.params.id)
			// 	.then(response => {
			// 		this.nameCountry = response.data.data.name	
			// 	})
			// 	.catch((error) => {
			// 		console.log(error)
			// 	})
			// },
			headClicked(key, isSort) {
				if(isSort.isSort == true){
					if(this.sort.field == key) {
						if(this.sort.type == 'asc') this.sort.type = 'desc'
						else this.sort.type = 'asc'
					} else {
						this.sort.field = key
						this.sort.type = 'desc'
					}								
					this.fetchItemByCountry(this.search)
				}
			},
			single_detail(item) {
		    	this.$router.push(`/admin/our-portfolio/study-visit/${this.$route.params.id}/edit/${item.id}`)
		  	},
			showDeleteAction(id){
				var title = null;
				var code_length = id.toString().length;

                switch (code_length) {
                    case 1:
						title = `SV0000${id}`
                        break;
                    case 2:
						title = `SV000${id}`
                        break;
                    case 3:
						title = `SV00${id}`
                        break;
                    case 4:
						title = `SV0${id}`
                        break;
                    default :
						title = `SV${id}`
                }
				this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
					title: 'Delete',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'Delete',
					cancelTitle: 'Cancel',
					cancelVariant: 'outline-danger',
					footerClass: 'p-2 modal-delete',
					hideHeaderClose: false,
					centered: true
				})
					.then(value => {
						if (value) {
							this.deleteItem(id, title)
						}
					})
					.catch(err => {
						// An error occurred
					})
			},
			deleteItem(id, title) {
				this.$store
					.dispatch('project/deleteVisit', {
						id: id
					})
					.then(response => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: title + ' Has been deleted!',
								variant: 'danger',
							},
						},
							{
								position: 'top-center'
							}
						)
						this.fetchItemByCountry(this.search)
					})
					.catch((error) => {
						console.log(error)
						this.$toast({
							component: ToastificationContent,
							props: {
								title: error.response.data.message,
								variant: 'danger',
							},
						},
							{
								position: 'top-center'
							})
					})
			},
		}
	};	

</script>