<template>
	<section>
		<b-col lg="4" class="text-right ml-auto">
			<template v-if="userData && permission.delete">
				<template v-if="detail.status != 0">
					<b-button type="button" variant="danger" class="btn-df size-18 black" @click="convertTerminate"
						style="margin-top: -17rem">
						Terminate
					</b-button>
				</template>
				<template v-else>
					<b-button type="button" variant="danger" class="btn-df size-18 black" @click="convertUnterminate"
						style="margin-top: -17rem">
						In process
					</b-button>
				</template>
			</template>
		</b-col>
		<div class="d-flex align-center white-bg pt-2">
			<b-col lg="6" style="padding-left: 33px ">
				<h2 class="mb-2"><strong>Basic Info</strong></h2>
			</b-col>
			<b-col lg-6 class="pr-2">
				<div class="text-right mb-2">
					<template v-if="(userData && permission.edit)">

						<b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction"
							style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px "
							v-if="!isEditing">Cancel</b-button>

						<b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction"
							v-if="!isEditing">
							Save
						</b-button>
						<b-button type="button" variant="primary" class="btn-df size-18 black" @click="editAction"
							v-else-if="isEditing">
							Edit
						</b-button>

					</template>
					<template v-else>
						<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
							Edit
						</b-button>
					</template>
				</div>
			</b-col>
		</div>
		<div style="border-top: 1px solid #000000B2;" class="pb-2 white-bg"></div>
		<validation-observer ref="form_rel" #default="{ invalid }">
			<b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
				<div class="frm-basic size-16 black-child d-block">
					<b-row style="width: 100%; margin: 0;">
						<b-col lg="6" class="px-0">
							<b-form-group label="Project Code" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="Project Code" rules="required">
									<v-select v-model="selectedCode" label="code" :options="projectCode"
										placeholder="Project Code" :disabled="isDisabled" :id="disableField" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Study Visit Owner" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="Project Owner" rules="required">
									<v-select v-model="selectedOwner" label="full_name" :options="selectOwner"
										placeholder="Select Owner" :disabled="isDisabled" :id="disableField" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Product Code" label-cols-lg="4" class="star-required input-group-label">
								<validation-provider #default="{ errors }" name="Product Code" rules="required">
									<b-form-input v-model="detail.product_code" placeholder="Product Code"
										:disabled="isDisabled" :formatter="length50"></b-form-input>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Client" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="Client" rules="required">
									<v-select v-model="selectedClient" label="name" :options="selectClient"
										placeholder="Select Organisation" :disabled="isDisabled" :id="disableField" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Country" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="selectedCountry" rules="required">
									<template>
										<v-select v-model="selectedCountry" label="name" :options="selectCountry"
											placeholder="Select Country" :disabled="isDisabled" :id="disableField" />
									</template>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Partner" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="Partner" rules="required">
									<v-select v-model="selectedPartner" label="name" :options="selectPartner"
										placeholder="Select Organisation" :disabled="isDisabled" :id="disableField" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Project Value" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="Project Value" rules="required">
									<itees-form-input-currency v-model="project_value_tam" placeholder="8,888.00"
										type="number" :disabled="isDisabled"
										:formatter="length20"></itees-form-input-currency>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Engagement Type" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="Engagement Type" rules="required">
									<v-select v-model="selectedType" label="title" :options="selectType"
										placeholder="Engagement" :disabled="isDisabled" :id="disableField" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Currency" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="Currency" rules="required">
									<v-select v-model="selectedCurrency" label="value" :options="selectCurrency"
										placeholder="SGD" :disabled="isDisabled" :id="disableField" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Service Type" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="Service Type" rules="required">
									<v-select v-model="selectedService" label="name" :options="selectService"
										placeholder="Service" :disabled="isDisabled" :id="disableField" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="No. of Participants" label-cols-lg="4"
								class="star-required input-group-label">
								<validation-provider #default="{ errors }" name="No. of Participants" rules="required">
									<b-form-input v-model="detail.no_of_participants" placeholder="0" type="number"
										:disabled="isDisabled" :formatter="length20"></b-form-input>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Reference No." label-cols-lg="4" class="input-group-label">
								<validation-provider #default="{ errors }" name="Reference No.">
									<b-form-input v-model="detail.reference_no" placeholder="Ref No."
										:disabled="isDisabled"></b-form-input>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0 cb-aligncenter pl-10t">
							<b-form-group label="ITE College Visited" label-cols-lg="4" class="star-required">
								<validation-provider #default="{ errors }" name="ITE College Visited" rules="required">
									<b-form-checkbox-group v-model="selectedCollege" :options="optionsCollege"
										name="cb-services" :disabled="isDisabled">
									</b-form-checkbox-group>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

						</b-col>
						<b-col lg="12" class="px-0">
							<b-form-group label="Description" label-cols-lg="2">
								<b-form-textarea v-model="detail.description" placeholder="Description" rows="3"
									:disabled="isDisabled" :formatter="length1000"></b-form-textarea>
							</b-form-group>
							<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Start Date" label-cols-lg="4" class="star-required">
								<div class="calendarIcon">
									<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
										<path fill="WindowText"
											d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
										<path fill="none" d="M0 0h24v24H0z" />
									</svg>
								</div>
								<flat-pickr v-model="detail.commencement_date" class="form-control fieldCalendar"
									:config="{ altInput: true, altFormat: 'd/m/y', dateFormat: 'Y-m-d' }"
									placeholder="Select a date" :disabled="isDisabled" />
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="End Date" label-cols-lg="4" class="star-required">
								<div class="calendarIcon">
									<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
										<path fill="WindowText"
											d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
										<path fill="none" d="M0 0h24v24H0z" />
									</svg>
								</div>
								<flat-pickr v-model="detail.completion_date" class="form-control fieldCalendar"
									:config="{ altInput: true, altFormat: 'd/m/y', dateFormat: 'Y-m-d' }"
									placeholder="Select a date" :disabled="isDisabled" />
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="Start Date Remarks" label-cols-lg="4">
								<b-form-textarea v-model="detail.commencement_remark" placeholder="Brief Description"
									rows="3" :disabled="isDisabled" :formatter="length1000"></b-form-textarea>
							</b-form-group>
						</b-col>
						<b-col lg="6" class="px-0">
							<b-form-group label="End Date Remarks" label-cols-lg="4">
								<b-form-textarea v-model="detail.completion_remark" placeholder="Brief Description" rows="3"
									:disabled="isDisabled" :formatter="length1000"></b-form-textarea>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-form>
		</validation-observer>
	</section>
</template>

<script>

const moment = require('moment')

import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';

import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import globalAdmin from '../../../model/globalAdmin';
export default {
	mixins: [globalAdmin],
	components: {
		Breadcrumb,
		flatPickr,
		vSelect,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			permission: [],
			projectCode: [],
			codetam: [],
			selectedCode: null,

			selectCountry: [],
			selectedCountry: null,

			selectOwner: [],
			selectedOwner: null,

			selectService: [],
			selectedService: null,

			selectClient: [],
			selectedClient: null,

			selectPartner: [],
			selectedPartner: null,

			selectCurrency: [],
			selectedCurrency: {},

			selectType: [
				{ title: 'Paid', value: '0' },
				{ title: 'Complimentary', value: '1' },
			],
			selectedType: {
				title: null,
				value: null
			},

			optionsCollege: [
				{ text: 'Central', value: 'Central' },
				{ text: 'East', value: 'East' },
				{ text: 'West', value: 'West' },
			],
			selectedCollege: [],

			tab_number: null,

			isEditing: true,
			isDisabled: true,
			disableField: 'disabled-field',
			detail: [],
			id_text: '',
			project_value_tam: 0,
		}
	},
	created() {
		this.permission = this.checkPermission(this.userData,"Study Visit") ?? []
		this.getProjectCode()
		this.listAllCountry()
		this.listOwner()
		this.listClient()
		this.listPartner()
		this.listCurrency()
		this.listServicesSetup()

		this.detailItem()

	},
	methods: {
		length50(e) {
			return String(e).substring(0, 50)
		},
		length20(e) {
			return String(e).substring(0, 20)
		},
		length1000(e) {
			return String(e).substring(0, 1000)
		},
		detailItem() {
			this.$store
				.dispatch('project/detailVisit', {
					id: this.$route.params.project_id
				})
				.then(response => {

					this.detail = response.data.data

					if (this.detail.id <= 9) this.id_text = 'SV000'
					else if (this.detail.id > 9 && this.detail.id <= 99) this.id_text = 'SV00'
					else if (this.detail.id > 99 && this.detail.id <= 999) this.id_text = 'SV0'
					else this.id_text = 'SV'

					document.title = this.id_text + response.data.data.id + ' - Study Vist Listing - ITEES | Your Global Partner of Choice'

					if (this.detail.project_code != null) this.getProjectCodebyId(this.detail.project_code)
					if (this.detail.country_id != null) this.getCountrybyId(this.detail.country_id)
					if (this.detail.user_id) this.getOwnerbyId(this.detail.user_id)
					if (this.detail.client) this.getClientbyId(this.detail.client)
					if (this.detail.partner) this.getPartnerbyId(this.detail.partner)
					if (this.detail.service_type != null) this.getServicebyId(this.detail.service_type)
					if (this.detail.currency_name) {
						this.selectedCurrency = this.detail.currency_name
					}

					if (this.detail.type_engagement == 0) {
						this.selectedType.title = 'Paid'
						this.selectedType.value = 0
					}
					if (this.detail.type_engagement == 1) {
						this.selectedType.title = 'Complimentary'
						this.selectedType.value = 1
					}
					this.selectedCollege = this.detail.college_visited

					if (this.detail && this.detail.project_value != null) {
						this.project_value_tam = this.detail.project_value
					}

					// console.log('detail visit: ', this.detail)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		editAction() {
			this.isDisabled = false
			this.isEditing = false
			this.disableField = ''
		},
		cancelAction() {
			if (this.$route.params && this.$route.params.id) {
				this.$router.push({ path: `/admin/our-portfolio/study-visit/${this.$route.params.id}` })
			}
			else {
				this.$router.push({ name: 'our-portfolio-study-visit' })
			}
		},
		saveAction() {
			this.$refs.form_rel.validate().then(success => {
				if (success) {
					if (this.selectedCountry.id) {
						let list = {
							project_code: this.selectedCode.id,
							product_code: this.detail.product_code,
							owner: this.selectedOwner.id,
							client: this.selectedClient.id,
							partner: this.selectedPartner.id,
							country_id: this.selectedCountry.id,
							project_value: this.project_value_tam,
							type_engagement: this.selectedType.value,
							currency_id: this.selectedCurrency ? this.selectedCurrency.id : null,
							service_type: this.selectedService ? this.selectedService.id : null,
							no_of_participants: this.detail.no_of_participants,
							reference_no: this.detail.reference_no,
							college_visited: this.selectedCollege,
							description: this.detail.description,
							commencement_date: this.detail.commencement_date,
							commencement_remark: this.detail.commencement_remark,
							completion_date: this.detail.completion_date,
							completion_remark: this.detail.completion_remark,
						}

						if (this.selectedType.value == 0) {
							this.tab_number = 1
						}
						else if (this.selectedType.value == 1) {
							this.tab_number = 2
						}

						// console.log('data: ', list)

						this.$store
							.dispatch('project/editVisit', {
								id: this.$route.params.project_id,
								data: list
							})
							.then(response => {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: this.id_text + this.detail.id + ' Updated!',
										variant: 'success',
									},
								},
									{
										position: 'top-center'
									})

								this.$router.push({ path: '/admin/our-portfolio/study-visit/' + this.selectedCountry.id, query: { tab: this.tab_number } })

							})
							.catch((error) => {
								console.log(error)
								this.$toast({
									component: ToastificationContent,
									props: {
										title: error.response.data.message,
										variant: 'danger',
									},
								},
									{
										position: 'top-center'
									})
							})
					}
					else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Please select Country',
								variant: 'danger',
							},
						},
							{
								position: 'top-center'
							})
					}
				}
				else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'One or more fields have an error. Please check and try again.',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
				}
			})
		},
		getProjectCodebyId(id) {
			this.$store
				.dispatch('auth/detailProjectCode', {
					id: id
				})
				.then(response => {
					this.selectedCode = response.data.data
					// console.log('this.selectedCode: ', this.selectedCode)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		getProjectCode() {
			this.$store
				.dispatch('auth/fetchProjectCodeList', {
				})
				.then(response => {
					this.codetam = response.data.data
					this.projectCode = this.codetam.map(
						(item) => {
							return {
								id: item.id,
								code: item.code
							}
						}
					)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		listAllCountry() {
			this.$store
				.dispatch('auth/fetchCountry')
				.then(response => {
					this.selectCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		getOwnerbyId(id) {
			this.$store
				.dispatch('auth/detailStaff', {
					id: id
				})
				.then(response => {
					this.selectedOwner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		listOwner() {
			this.$store
				.dispatch('auth/fetch_staff_log_list', {
					filter: { "role": "4" }
				})
				.then(response => {
					this.selectOwner = response.data.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		getClientbyId(id) {
			this.$store
				.dispatch('auth/detailOrganisation', {
					id: id
				})
				.then(response => {
					this.selectedClient = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		listClient() {
			this.$store
				.dispatch('auth/Client_Organisation_List', {
					filter: {
						"list_search": [{ "field": "client", "keyword": "1" }]
					}
				})
				.then(response => {
					this.selectClient = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		getPartnerbyId(id) {
			this.$store
				.dispatch('auth/detailOrganisation', {
					id: id
				})
				.then(response => {
					this.selectedPartner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		listPartner() {
			this.$store
				.dispatch('auth/Client_Organisation_List', {
					filter: {
						"list_search": [{ "field": "partner", "keyword": "1" }]
					}
				})
				.then(response => {
					this.selectPartner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		listCurrency() {
			this.$store
				.dispatch('project/getCurrencyList')
				.then(response => {
					this.selectCurrency = response.data.data
					// console.log('this.selectCurrency: ', this.selectCurrency)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		getServicebyId(id) {
			this.$store
				.dispatch('auth/detailServicesSetup', {
					id: id
				})
				.then(response => {
					this.selectedService = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		listServicesSetup() {
			this.$store
				.dispatch('auth/fetchServicesSetup', {
					filter: {
						"list_search": [
							{
								"field": "status",
								"keyword": 1
							}
						]
					}
				})
				.then(response => {
					this.selectService = response.data.data
					// console.log('this.selectService: ', this.selectService)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		getCountrybyId(id) {
			this.$store
				.dispatch('auth/detailCountry', {
					id: id
				})
				.then(response => {
					this.selectedCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		convertTerminate() {
			this.$bvModal.msgBoxConfirm('Please confirm that you want to Terminate.', {
				title: 'Please Confirm',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'YES',
				cancelTitle: 'NO',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.$store
							.dispatch('project/convertTerminateVisit', {
								id: this.$route.params.project_id,
								status: 0
							})
							.then(response => {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: this.id_text + this.detail.id + ' Updated!',
										variant: 'danger',
									},
								},
									{
										position: 'top-center'
									}
								)
								this.$router.push({ path: '/admin/our-portfolio/study-visit/' + this.selectedCountry.id, query: { tab: 3 } })
							})
							.catch((error) => {
								console.log(error)
							})
					}
				})
				.catch(err => { })
		},
		convertUnterminate() {
			this.$bvModal.msgBoxConfirm('Please confirm that you want to Active.', {
				title: 'Please Confirm',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'YES',
				cancelTitle: 'NO',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.$store
							.dispatch('project/convertTerminateVisit', {
								id: this.$route.params.project_id,
								status: 1
							})
							.then(response => {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: this.id_text + this.detail.id + ' Updated!',
										variant: 'danger',
									},
								},
									{
										position: 'top-center'
									}
								)
								this.$router.push({ path: '/admin/our-portfolio/study-visit/' + this.selectedCountry.id, query: { tab: parseInt(this.detail.type_engagement + 1) } })
							})
							.catch((error) => {
								console.log(error)
							})
					}
				})
				.catch(err => { })
		},
	}
};

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@/assets/scss/_ite_tab_table.scss";

.Vue-Toastification__container.top-center {
	top: 20px;
}
</style>