<template>
	<section>
		<template v-if="this.$route.name == 'our-portfolio-study-detail-blank'">
			<b-row style="justify-content: space-between; align-items: center" class="mb-2">
			    <b-col lg="8">
					<Breadcrumb :labels="labels" />				
			    </b-col>
			    <b-col lg="4">
			    	<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
				      	<b-form-group class="mb-0">
					        <b-input-group class="input-group-merge">
						        <b-input-group-prepend is-text>
						            <feather-icon icon="SearchIcon" size="17" />
						        </b-input-group-prepend>
						        <b-form-input
						        	type="text"
						            placeholder="Search"
						            @input="searchItem"
						        />
					        </b-input-group>
				      	</b-form-group>
				    </div>
			    </b-col>				
			</b-row>
			<b-row>
			    <b-col md="12">
			    	<div>
				        <div class="btn-action text-right mb-2">

				            <b-button type="button" variant="primary" class="px-1 mr-10 py-1  size-18 btn-df" @click="exportData(export_num)">Export</b-button>

				            <template v-if="(userData && permission.create)">
				            	<b-button type="button" variant="primary" class="btn-df py-1 size-18" @click="addItem">Add Visit</b-button>
				            </template>
				            <template v-else>
						    	<b-button type="button" variant="primary" class="btn-df py-1 size-18 btn-disabled-gray" disabled>Add Visit</b-button>
						    </template>

				        </div>
				        <b-tabs card class="caption-add table-custom" v-model="tabIndex_visit">                       
				            <b-tab title="Overview" class="overview-tab">
				                <detailOverviewTab ref="tab_0" :search="search_2" />
				            </b-tab>
				            <b-tab title="Paid" class="paid-tab">
				            	<PaidTab ref="tab_1" :search="search_2" />
				            </b-tab>
				            <b-tab title="Complimentary" class="compl-tab">
				            	<ComTab ref="tab_2" :search="search_2" />
				            </b-tab>
				            <b-tab title="Terminated">
				            	<TermTab ref="tab_3" :search="search_2" :permission="permission" />
				            </b-tab>                        
				        </b-tabs>     
				    </div>
				</b-col>
			</b-row>
		</template>
		<template v-else>

			<b-row class="mb-2">
				<b-col lg="8">
					<Breadcrumb :labels="labels" :detailName="detailName" />				
			    </b-col>
			</b-row>
		    <b-tabs card class="caption-add table-custom">

	            <b-tab title="Basic Info" class="overview-tab">	
	            	<template v-if="this.$route.name == 'our-portfolio-study-visit-add' || this.$route.name == 'our-portfolio-study-visit-add' ">
						<addVisit />
				    </template>
				    <template v-else>
						<editVisit />
				    </template>
	            </b-tab>
	            <b-tab title="Gifts" :disabled= "disableTabOnAdd">
	            	<tabGift :permission="permission" />
	            </b-tab>
	            <b-tab title="Documents" class="tab-child tab-pt-0" :disabled= "disableTabOnAdd">
	            	<tabDocument :permission="permission" />	
	            </b-tab> 

	        </b-tabs> 
		</template>				
	</section>
</template>

<script>

	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';
	import detailOverviewTab from './tab-detail/OverviewTab';
	import PaidTab from './tab-detail/PaidTab'
	import ComTab from './tab-detail/ComTab'
	import TermTab from './tab-detail/TermTab'
	import addVisit from '@/views/itees/admin-dashboard/portfolio/studyVisit/single/Add'
	import editVisit from '@/views/itees/admin-dashboard/portfolio/studyVisit/single/Edit'

	import tabGift from '@/views/itees/admin-dashboard/portfolio/studyVisit/single/gifts/List'
	import tabDocument from '@/views/itees/admin-dashboard/portfolio/studyVisit/single/document/List'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../../../model/globalAdmin'
	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		detailOverviewTab,
	  		PaidTab,
	  		ComTab,
	  		TermTab,
	  		addVisit,
	  		editVisit,
	  		tabGift,
	  		tabDocument
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	permission: [],
				labels: { "our-portfolio-study-detail": "" },
				tam: [],

				disableTabOnAdd: false,
				tabIndex_visit: 0,
				export_num: -1,
				detailName: { "our-portfolio-study-detail-edit": "" },
				id_text: '',

				search_2: null,
		    	timer_2: null,
            }
		},	
		watch: {
			"$route": function(val) {
				if(val){	

					if(this.$route && this.$route.query && this.$route.query.tab){
					 	this.tabIndex_visit = Number(this.$route.query.tab)	
					 	this.fetchCountryID()
					}

					if(val.name == 'our-portfolio-study-visit-add'){
						this.disableTabOnAdd = true
					}
					else{
						this.disableTabOnAdd = false
					}

					if(this.$route.params){
						if(this.$route.params.project_id &&  this.$route.name == 'our-portfolio-study-detail-edit' ) {
							this.getNameProject()
						}
					}	
				}
			},
			tabIndex_visit: function(val) {
                this.loadDataToTab(this.search_2)
                this.export_num = parseInt(val) - 1
            },
            search_2(val) {
                this.loadDataToTab(val)             
            }, 
		},	
		mounted() {
			if(this.$route.name == 'our-portfolio-study-detail-blank' ){
				 this.$nextTick(() => {
	                this.loadDataToTab(this.search_2)
	            })
			}
        },
		created(){
			this.permission = this.checkPermission(this.userData,"Study Visit") ?? []
			if(this.$route.params.id) this.fetchCountryID()

			if(this.$route && this.$route.query && this.$route.query.tab)
			{	
				this.tabIndex_visit = Number(this.$route.query.tab)	
				this.fetchCountryID()
			} 

			if(this.$route.name){
				if(this.$route.name == 'our-portfolio-study-visit-add' ){
					this.disableTabOnAdd = true
				}	
				else{
					this.disableTabOnAdd = false
				}			
			}
			if(this.$route.params){
				if(this.$route.params.project_id &&  this.$route.name == 'our-portfolio-study-detail-edit' ) {
					this.getNameProject()
				}
			}	
		},
		methods: {
			searchItem(val){
				if (this.timer_2) {
			        clearTimeout(this.timer_2);
			        this.timer_2 = null;			        
			    }
			    this.timer_2 = setTimeout(() => {
			        this.search_2 = val
			    }, 200);
			},
			loadDataToTab(val) {
                this.$refs[`tab_${this.tabIndex_visit}`].currentPage = 1
                this.$refs[`tab_${this.tabIndex_visit}`].fetchItemByCountry(val)
            },
			fetchCountryID(){
				this.$store
				.dispatch('country/fetchCountryID', this.$route.params.id)
				.then(response => {
					this.labels['our-portfolio-study-detail'] = response.data.data.name	
					document.title = response.data.data.name + ' - Study Vist Listing - ITEES | Your Global Partner of Choice'	
				})
				.catch((error) => {
					console.log(error)
				})
			},
			addItem(){
				this.$router.push(`/admin/our-portfolio/study-visit/${this.$route.params.id}/add-visit`)
			},
			exportData(item) {
				if(item < 0){
					this.$store.dispatch('project/exportVisit', {
						country_id: this.$route.params.id,

					}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]))
					    const link = document.createElement('a')
					    link.href = url
					    link.setAttribute('download', 'Study-visit-listing.csv') //or any other extension
					    document.body.appendChild(link)
					    link.click()
					})
					.catch((error) => {
						console.log(error)
					})
				}
				else{
					this.$store.dispatch('project/exportVisit', {
						country_id: this.$route.params.id,
						type_engagement: item

					}).then(response => {
						const url = window.URL.createObjectURL(new Blob([response.data]))
					    const link = document.createElement('a')
					    link.href = url
					    link.setAttribute('download', 'Study-visit-listing.csv') //or any other extension
					    document.body.appendChild(link)
					    link.click()
					})
					.catch((error) => {
						console.log(error)
					})
				}
				
			},
			getNameProject(){
				this.$store
				.dispatch('project/detailVisit', {
					id: this.$route.params.project_id	
				})
				.then(response => {

					if(response.data.data.id <= 9) this.id_text = 'SV000'
					else if(response.data.data.id > 9 && response.data.data.id <= 99) this.id_text = 'SV00'
					else if(response.data.data.id > 99 && response.data.data.id <= 999) this.id_text = 'SV0'
					else this.id_text = 'SV'

					this.detailName['our-portfolio-study-detail-edit'] = this.id_text + response.data.data.id
				
				})
				.catch((error) => {
					console.log(error)
				})
			}
		}
	};	

</script>

<style lang="scss">

	@import "@/assets/scss/_ite_tab_table.scss";

</style>