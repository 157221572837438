<template>
	<section>
		<div class="d-flex align-center white-bg pt-2">
	        <b-col lg="6" style="padding-left: 33px ">
	            <h2 class="mb-2"><strong>Basic Info</strong></h2>
	        </b-col>
	        <b-col lg-6 class="pr-2">	        		
	        	<div class="text-right mb-2">

	                <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

	                <template v-if="(userData)">
		                <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction">
		                    Save
		                </b-button>
		            </template>
		            <template v-else>
		            	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
		                    Save
		                </b-button>
		            </template>

	            </div>							                
	        </b-col>
	    </div>				                
	    <div style="border-top: 1px solid #000000B2;" class="pb-2 white-bg"></div>
		<validation-observer
	        ref="form_rel"
	        #default="{invalid}" >
	        <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
	            <div class="frm-basic size-16 black-child d-block">
	              	<b-row style="width: 100%; margin: 0;">  
	                  	<b-col lg="6" class="px-0">
	                      	<b-form-group label="Project Code" label-cols-lg="4" class="star-required">
	                      		<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Code"
	                              	rules="required"                          
	                            >      
	                                <v-select
	                                	v-model="selectedCode"
	                                    label="code"
	                                    :options="projectCode"
	                                    placeholder="Project Code"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                     	</b-form-group>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Study Visit Owner" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Owner"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedOwner"
	                                    label="full_name"
	                                    :options="selectOwner"
	                                    placeholder="Select Owner"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0"> 
	                      	<b-form-group label="Product Code" label-cols-lg="4" class="star-required input-group-label">  
	                      		<validation-provider
	                              	#default="{ errors }"
	                              	name="Product Code"
	                              	rules="required"                                  
	                            >                          
	                                <b-form-input
	                                	v-model="fields.product_code"
	                                    placeholder="Product Code"
										:formatter= "length50"	
	                                ></b-form-input>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                      	</b-form-group>
	                  	</b-col> 
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Client" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Client"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedClient"
	                                    label="name"
	                                    :options="selectClient"
	                                    placeholder="Select Organisation"
	                                />	
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>               
	                        </b-form-group>
	                  	</b-col> 
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Country" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="selectedCountry"
	                              	rules="required"
	                            >     
	                            	<template>
	                            		<v-select
		                                	v-model="selectedCountry"
		                                    label="name"
		                                    :options="selectCountry"
		                                    placeholder="Select Country"
		                                />
	                            	</template>	                                
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Partner" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Partner"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedPartner"
	                                    label="name"
	                                    :options="selectPartner"
	                                    placeholder="Select Organisation"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Value" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Value"
	                              	rules="required"                                  
	                            >      
	                            	<itees-form-input-currency
	                            		v-model="fields.project_value"
	                                    placeholder="8,888.00"
	                                    type="number"
	                                    required
										:formatter= "length20"
	                                ></itees-form-input-currency>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>   
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Engagement Type" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Engagement Type"
	                              	rules="required"                                  
	                            >      	
	                                <v-select
	                                	v-model="selectedType"
	                                    label="title"
	                                    :options="selectType"
	                                    placeholder="Engagement"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Currency" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Currency"
	                              	rules="required"                                  
	                            >      	
	                                <v-select
	                                	v-model="selectedCurrency"
	                                    label="value"
	                                    :options="selectCurrency"
	                                    placeholder="SGD"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Service Type" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Service Type"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedService"
	                                    label="name"
	                                    :options="selectService"
	                                    placeholder="Service"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="No. of Participants" label-cols-lg="4" class="star-required input-group-label">
	                        	<validation-provider 
	                              	#default="{ errors }"
	                              	name="No. of Participants"
	                              	rules="required"                                  
	                            >      
	                            	<b-form-input
	                            		v-model="fields.no_part"
	                                    placeholder="0"
	                                    type="number"
										:formatter="length20"
	                                ></b-form-input>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Reference No." label-cols-lg="4" class="input-group-label">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Reference No."                               
	                            >      
	                            	<b-form-input
	                            		v-model="fields.reference"
	                                    placeholder="Ref No."
	                                ></b-form-input>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>
	                  	<b-col lg="6" class="px-0 cb-aligncenter pl-10t">  
                      		<b-form-group label="ITE College Visited" label-cols-lg="4" class="star-required">
                      			<validation-provider
	                              	#default="{ errors }"
	                              	name="ITE College Visited"
	                              	rules="required"                  
	                            > 
	                          		<b-form-checkbox-group
									    v-model="selectedCollege"
								        :options="optionsCollege"
								        name="cb-services"
									>
									</b-form-checkbox-group>
									<small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
							</b-form-group>

                      	</b-col> 
	                  	<b-col lg="12" class="px-0">
	                        <b-form-group label="Description" label-cols-lg="2">
	                        	<b-form-textarea
	                        		v-model="fields.description"
	                                placeholder="Description"
	                                rows="3"
									:formatter= "length1000"
	                            ></b-form-textarea>
	                        </b-form-group>
	                        <div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Start Date" label-cols-lg="4" class="star-required">   
	                        	<div class="calendarIcon">
	                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
	                        	</div>
                                <flat-pickr
                                	v-model="fields.start_date"
                                  	class="form-control fieldCalendar"           
                                  	:config="{ altInput: true , altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: mintoday}"
                                  	placeholder="Select a date"
                                  	required
                                />
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="End Date" label-cols-lg="4" class="star-required">
                                <div class="calendarIcon">
	                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
	                        	</div>
                                <flat-pickr
                                	v-model="fields.end_date"
                                  	class="form-control fieldCalendar"           
                                  	:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: mintoday}"
                                  	placeholder="Select a date"
                                  	required
                                />
	                        </b-form-group>
	                  	</b-col> 	       
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Start Date Remarks" label-cols-lg="4">
	                        	<b-form-textarea
	                        		v-model="fields.start_remark"
	                                placeholder="Brief Description"
	                                rows="3"
									:formatter= "length1000"
	                            ></b-form-textarea>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="End Date Remarks" label-cols-lg="4">
	                        	<b-form-textarea
	                        		v-model="fields.end_remark"
	                                placeholder="Brief Description"
	                                rows="3"
									:formatter= "length1000"
	                            ></b-form-textarea>
	                        </b-form-group>
	                  	</b-col> 	 
	              	</b-row>  
	            </div>
	        </b-form> 
	    </validation-observer>	
	</section>
</template>

<script>

	const moment = require('moment')

	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';

	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {	   
			    userData: JSON.parse(localStorage.getItem('userData')),
			    fields: {
			    	product_code: null,
			    	project_value: 0,
			    	no_part: null,
			    	reference: null,
			    	description: null, 
			    	start_date: null,
			    	end_date: null,
			    	start_remark: null, 
			    	end_remark: null, 
			    	project_remark: null,  
			    },
			    projectCode: [],
			    codetam: [],
			    selectedCode: null,

			    selectCountry: [], 
			    selectedCountry: {
			    	name: 'Select Country',
			    	id: null
			    },

			    selectOwner: [],
                selectedOwner: null,

			    selectService: [],
		        selectedService: null,

		        selectClient: [],
		        selectedClient: null,
		        
		        selectPartner: [],
		        selectedPartner: null,

		        selectCurrency: [],
		        selectedCurrency: null,

		        selectType: [
		        	{title: 'Paid', value: '0'},
		        	{title: 'Complimentary', value: '1'},
		        ],
		        selectedType: null,

		        optionsCollege: [
		          { text: 'Central', value: 'Central' },
		          { text: 'East', value: 'East' },
		          { text: 'West', value: 'West' },
		        ],
		        selectedCollege: [],

		        tab_number: null,
            }
		},	
		computed: {
	        mintoday(){            
	            var d = new Date()
	            d.setDate(d.getDate())
	            var now = moment(d).format('YYYY-MM-DD')
	            return now
	        },
	    },	
		created(){
			this.getProjectCode()
			this.listAllCountry()
			this.listOwner()
			this.listClient()
			this.listPartner()
			this.listCurrency()
			this.listServicesSetup()
			if(this.$route.params.id) {
				this.getCountrybyID()
			}
			else {
				this.selectedCountry = null
			}
		},
		methods: {
			length50(e){
	            return String(e).substring(0,50)
	        },
	        length20(e){
	            return String(e).substring(0,20)
	        },
	        length1000(e){
	            return String(e).substring(0,1000)
	        },
			cancelAction(){
				if(this.$route.params && this.$route.params.id) {
                	this.$router.push({ path: `/admin/our-portfolio/study-visit/${this.$route.params.id}` })
                }
                else {
                	this.$router.push({ name: 'our-portfolio-study-visit' })
            	}
			},
			saveAction(){
				this.$refs.form_rel.validate().then(success => {
                	if(success){
                		if(this.selectedCountry.id){
	                		let list = {
			                    project_code: this.selectedCode.id,
			                    product_code: this.fields.product_code,
			                    owner: this.selectedOwner.id, 
			                    client: this.selectedClient.id,
			                    partner: this.selectedPartner.id,
			                    country_id: this.selectedCountry.id,
			                    project_value: this.fields.project_value,
			                    type_engagement: this.selectedType.value,
			                    currency_id: this.selectedCurrency ? this.selectedCurrency.id : null,
			                    service_type: this.selectedService ? this.selectedService.id : null,
			                    no_of_participants: this.fields.no_part,
			                    reference_no: this.fields.reference,
			                    college_visited: this.selectedCollege,
			                    description: this.fields.description,
			                    commencement_date: this.fields.start_date,
			                    commencement_remark: this.fields.start_remark,
			                    completion_date: this.fields.end_date,
			                    completion_remark: this.fields.end_remark,
			                }
			                // console.log('data: ', list)

			                if(this.selectedType.value == 0){
			                	this.tab_number = 1
			                }
			                else if(this.selectedType.value == 1){
			                	this.tab_number = 2
			                }

			                this.$store
			                .dispatch('project/addVisit', list)
			                .then(response => {
			                    this.$toast({
			                        component: ToastificationContent,
			                        props: {
			                            title: 'New visit Created!',
			                            variant: 'success',
			                        },
			                    },
			                    {
			                        position: 'top-center'
			                    })

			                	this.$router.push({ path: '/admin/our-portfolio/study-visit/' + this.selectedCountry.id, query: { tab: this.tab_number }})
			                	
			                })
			                .catch((error) => {
			                  	console.log(error)
			                  	this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                        	title: error.response.data.message,
			                        	variant: 'danger',
			                      	},
			                    },
			                    {
			                      position: 'top-center'
			                    })
			                })  
			            }
			            else{
			            	this.$toast({
			                    component: ToastificationContent,
			                    props: {
			                        title: 'Please select Country',
			                        variant: 'danger',
			                      },
			                },
			                {
			                      position: 'top-center'
			                })
			            }
                	}
                	else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
              	})
			},
			getProjectCode(){
				this.$store
				.dispatch('auth/fetchProjectCodeList', {	
				})
				.then(response => {
					this.codetam = response.data.data
					this.projectCode = this.codetam.map(
		                (item) => {
		                	return{
		                		id: item.id,
		                		code: item.code
		                	}		                	
		                }
		              )
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listAllCountry(){
				this.$store
				.dispatch('auth/fetchCountry')
				.then(response => {
					this.selectCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listOwner(){
				this.$store
				.dispatch('auth/fetch_staff_log_list', {
		            filter: {"role":"4"}
		        })
				.then(response => {
					this.selectOwner = response.data.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listClient(){
				this.$store
				.dispatch('auth/Client_Organisation_List', {
		            filter: { 
		            	"list_search": [{ "field": "client","keyword": "1"}]
		        	}
		        })
				.then(response => {
					this.selectClient = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listPartner(){
				this.$store
				.dispatch('auth/Client_Organisation_List', {
		            filter: { 
		            	"list_search": [{ "field": "partner","keyword": "1"}]
		        	}
		        })
				.then(response => {
					this.selectPartner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listCurrency(){
				this.$store
				.dispatch('project/getCurrencyList')
				.then(response => {
					this.selectCurrency = response.data.data
					// console.log('this.selectCurrency: ', this.selectCurrency)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listServicesSetup(){
				this.$store
				.dispatch('auth/fetchServicesSetup', {	
					filter: {
					  "list_search": [
					    {
					      "field": "status",
					      "keyword": 1
					    }
					  ]
					}
				})
				.then(response => {
					this.selectService = response.data.data
					// console.log('this.selectService: ', this.selectService)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getCountrybyID(){				
				this.$store
				.dispatch('country/fetchCountryID', this.$route.params.id)
				.then(response => {
					this.selectedCountry.name = response.data.data.name
					this.selectedCountry.id = parseInt(this.$route.params.id)
				})
				.catch((error) => {
					console.log(error)
				})
			},
		}
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}	
</style>